import _objectSpread from "D:/projects/operation-management-background/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.array.push.js";
import { CheckerApi } from "@/api";
import Mixin from "@/utils/mixin";
export default {
  data: function data() {
    return {
      imgProportion: [800, 800],
      previewVisible: false,
      previewImage: "",
      list: [],
      dataSource: [],
      data: [],
      userid: this.$route.query.id,
      status: [{
        id: 0,
        name: "正常"
      }, {
        id: 1,
        name: "停用"
      }]
    };
  },
  mixins: [Mixin],
  computed: {},
  created: function created() {
    this.getlist();
  },
  methods: {
    handleCancel: function handleCancel() {},
    getlist: function getlist() {
      var _this = this;
      var params = {
        id: this.$route.query.id
      };
      CheckerApi.getcheckerinfo(params).then(function (res) {
        if (res) {
          _this.loading = false;
          _this.data = res || [];
          console.log('this.data', _objectSpread({}, _this.data));
          if (_this.data.saletime) {
            _this.saletime = /\d{4}-\d{1,2}-\d{1,2}/g.exec(_this.data.saletime)[0];
          } else {
            _this.saletime = "";
          }
          _this.data.saletime = _this.saletime;
          _this.loading = false;
        }
      });
    },
    editorClick: function editorClick() {
      this.$router.push({
        path: "/check/user/edit",
        query: {
          id: this.$route.query.id
        }
      });
    },
    preview: function preview() {
      var previewImage = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : "";
      this.previewImage = previewImage;
      this.previewVisible = true;
    },
    previewHandleCancel: function previewHandleCancel() {
      this.previewVisible = false;
    },
    goInfo: function goInfo() {
      this.$router.push({
        path: "/check/settlement/detail",
        query: {
          checkerid: this.$route.query.id
        }
      });
    }
  }
};
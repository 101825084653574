import "core-js/modules/es.array.join.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("a-card", {
    staticStyle: {
      "overflow-x": "scroll",
      "margin-bottom": "24px"
    },
    attrs: {
      bordered: false
    }
  }, [_c("div", {
    staticStyle: {
      "margin-bottom": "16px"
    }
  }, [_c("span", {
    staticClass: "title",
    staticStyle: {
      "margin-right": "12.5px"
    }
  }, [_vm._v("基本信息")]), _c("img", {
    directives: [{
      name: "hasPermission",
      rawName: "v-hasPermission",
      value: ["check:checker:edit"],
      expression: "['check:checker:edit']"
    }],
    staticStyle: {
      width: "20px"
    },
    attrs: {
      src: require("../../assets/img/editIcon.png"),
      alt: ""
    },
    on: {
      click: function click($event) {
        return _vm.editorClick();
      }
    }
  })]), _c("div", {
    staticClass: "basic-message"
  }, [_c("div", {
    staticClass: "proImg"
  }, [_c("img", {
    attrs: {
      src: _vm.data.headimgurl,
      alt: ""
    }
  })]), _c("div", {
    staticClass: "basic-info"
  }, [_c("a-row", {
    staticStyle: {
      "margin-bottom": "16px"
    },
    attrs: {
      type: "flex",
      justify: "center"
    }
  }, [_c("a-col", {
    attrs: {
      span: 8
    }
  }, [_c("span", {
    staticClass: "info-title title-left"
  }, [_vm._v("鉴定师昵称")]), _c("span", [_vm._v(_vm._s(_vm.data.username || "---"))])]), _c("a-col", {
    attrs: {
      span: 8
    }
  }, [_c("span", {
    staticClass: "info-title"
  }, [_vm._v("账户名称")]), _c("span", [_vm._v(_vm._s(_vm.data.nickname || "--"))])]), _c("a-col", {
    attrs: {
      span: 8
    }
  }, [_c("span", {
    staticClass: "info-title"
  }, [_vm._v("创建时间")]), _c("span", [_vm._v(_vm._s(_vm.data.createtime || "--"))])])], 1), _c("a-row", {
    staticStyle: {
      "margin-bottom": "16px"
    },
    attrs: {
      type: "flex",
      justify: "center"
    }
  }, [_c("a-col", {
    attrs: {
      span: 8
    }
  }, [_c("span", {
    staticClass: "info-title if-title-left"
  }, [_vm._v("状态")]), _c("span", [_vm._v(_vm._s(_vm.data.status == 0 ? "正常" : "停用" || "--"))])]), _c("a-col", {
    attrs: {
      span: 8
    }
  }, [_c("span", {
    staticClass: "info-title"
  }, [_vm._v("在线时间")]), _c("span", [_vm._v(_vm._s(_vm.data.onlinetimebegin + "-" + _vm.data.onlinetimeend || "-- "))])]), _c("a-col", {
    attrs: {
      span: 8
    }
  })], 1)], 1)])]), _c("a-card", {
    staticClass: "details",
    staticStyle: {
      "margin-bottom": "24px"
    },
    attrs: {
      bordered: false
    }
  }, [_c("div", {
    staticClass: "title"
  }, [_vm._v("资质信息")]), _c("span", {
    staticClass: "box"
  }, [_c("span", {
    staticClass: "item"
  }, [_c("span", {
    staticClass: "it_title"
  }, [_vm._v("可鉴定品牌")]), _c("span", {
    staticClass: "it_value"
  }, [_vm._v(_vm._s(_vm.data.brands && _vm.data.brands.join("、") || "---"))])]), _c("span", {
    staticClass: "item"
  }, [_c("span", {
    staticClass: "it_title"
  }, [_vm._v("可鉴定类目")]), _c("span", {
    staticClass: "it_value"
  }, [_vm._v(_vm._s(_vm.data.categorys && _vm.data.categorys.join("、") || "--"))])]), _c("span", {
    staticClass: "item"
  }, [_c("span", {
    staticClass: "it_title"
  }, [_vm._v("支持快速鉴定")]), _c("span", {
    staticClass: "it_value"
  }, [_vm._v(_vm._s(_vm.data.fastcheck == 0 ? "否" : "是" || "-- "))])]), _c("span", {
    staticClass: "item"
  }, [_c("span", {
    staticClass: "it_title"
  }, [_vm._v("可鉴定品牌数量")]), _c("span", {
    staticClass: "it_value"
  }, [_vm._v(_vm._s(_vm.data.brandcount || "--"))])]), _c("span", {
    staticClass: "item"
  }, [_c("span", {
    staticClass: "it_title"
  }, [_vm._v("可鉴定类目数量")]), _c("span", {
    staticClass: "it_value"
  }, [_vm._v(_vm._s(_vm.data.categorycount || "--"))])])])]), _c("a-card", {
    staticClass: "details",
    staticStyle: {
      "margin-bottom": "24px"
    },
    attrs: {
      bordered: false
    }
  }, [_c("div", {
    staticClass: "title"
  }, [_vm._v("结算信息")]), _c("span", {
    staticClass: "box"
  }, [_c("span", {
    staticClass: "item"
  }, [_c("span", {
    staticClass: "it_title"
  }, [_vm._v("结算方式")]), _c("span", {
    staticClass: "it_value"
  }, [_vm._v(_vm._s(_vm.data.paytype || "--"))])]), _c("span", {
    staticClass: "item"
  }, [_c("span", {
    staticClass: "it_title"
  }, [_vm._v("结算账户")]), _c("span", {
    staticClass: "it_value"
  }, [_vm._v(_vm._s(_vm.data.paynumber || "--"))])]), _c("span", {
    staticClass: "item"
  }, [_c("span", {
    staticClass: "it_title"
  }, [_vm._v("鉴定数量")]), _c("span", {
    staticClass: "it_value"
  }, [_vm._v(_vm._s(_vm.data.checkcount || "--"))])]), _c("span", {
    staticClass: "item"
  }, [_c("span", {
    staticClass: "it_title"
  }, [_vm._v("鉴定收入")]), _c("span", {
    staticClass: "it_value"
  }, [_vm._v(_vm._s(_vm.data.income || "--"))])]), _c("span", {
    staticClass: "item"
  }, [_c("span", {
    staticClass: "it_title"
  }, [_vm._v("最终收入")]), _c("span", {
    staticClass: "it_value"
  }, [_vm._v(_vm._s(_vm.data.amount || "--"))]), _c("span", {
    staticClass: "goInfo",
    on: {
      click: _vm.goInfo
    }
  }, [_vm._v("查看结算详情")])])])])], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };